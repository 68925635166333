<template>
  <v-container fluid class="px-8 py-0">
    <bridge-layout ref="bridgeLayout">
      <template #top>
        <section class="py-4">
          <v-row class="mb-4 mt-2">
            <v-col cols="12" md="6" class="d-flex py-0">
              <v-chip label class="button-chip">
                {{ filteredAssignments.length }} {{ pluralize('Assignment', filteredAssignments.length) }}
              </v-chip>
            </v-col>

            <v-col cols="12" md="6" class="d-flex justify-end py-0">
              <v-btn class="my-0 mx-2" dark color="primary" @click="printAssignmentList()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-tooltip v-if="notifyConfig.driverDecline || notifyConfig.driverAccept" bottom contained>
                <template v-slot:activator="{ on, attrs }">
                  <small>
                    <v-chip
                      outlined
                      rounded
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-information"
                      class="mx-2 button-chip"
                    >
                      <v-icon class="mr-2" color="blue">mdi-information</v-icon>Color Codes</v-chip
                    >
                  </small>
                </template>

                <div>
                  <div class="font-weight-bold green--text text--darken-1">Driver Accepted</div>
                  <div class="font-weight-bold blue--text text--darken-2">Driver Assigned (Pending Action)</div>
                  <div class="font-weight-bold red--text text-accent-2">Driver Declined</div>
                </div>
              </v-tooltip>

              <v-switch
                class="ma-0 pa-0 mx-4 mt-2 d-inline-block"
                v-model="allExpanded"
                label="Expand All"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-toolbar height="50" elevation="4" rounded>
            <AssignmentListMenu />
          </v-toolbar>
        </section>
      </template>

      <template #list>
        <div>
          <v-col cols="12" class="pa-0 d-flex flex-column justify-center">
            <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
              class="progress"
            ></v-progress-circular>
          </v-col>

          <assignment
            :key="a.id"
            :assignmentProp="a"
            :allExpanded="allExpanded"
            :groupNext="
              i + 1 < paginatedAssignments.length && paginatedAssignments[i + 1].tripRequestId == a.tripRequestId
            "
            class="card-spacing"
            v-for="(a, i) of paginatedAssignments"
          ></assignment>
        </div>
      </template>

      <template #bottom>
        <v-toolbar height="50" elevation="4" rounded class="my-4 d-flex flex-row justify-center">
          <v-pagination
            :length="totalPages"
            :totalVisible="10"
            @input="$refs.bridgeLayout.resetScroll()"
            v-model="currentPage"
          ></v-pagination
        ></v-toolbar>
      </template>
    </bridge-layout>

    <view-available-vehicles ref="fav"></view-available-vehicles>

    <batch-assign ref="batchAssign"></batch-assign>

    <date-range ref="dateRange" filter="assignment"></date-range>

    <id-picker
      ref="idPicker"
      title="Trip #"
      filterType="assignment"
      :filter="{ name: 'Trip #', field: 'id' }"
    ></id-picker>

    <Dialog v-model="driverLogDialog">
      <template #header>Driver Logs</template>

      <template #body>
        <v-container fluid>
          <driver-log></driver-log>
        </v-container>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { uniq } from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { DOWNLOAD_TRIP_TICKET } from '@/store/modules/TripRequest/actions';
import { GET_ASSIGNMENTS, PRINT_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import { GET_CONFIG, GET_SPECIAL_INDICATORS } from '@/store/modules/Config/actions';
import { GET_USERS, GET_VEHICLE_OWNERS } from '@/store/modules/User/actions';
import {
  todayString,
  getVehicleLocation,
  randomString,
  getVehicleOwner,
  pluralize,
  getAssignmentVehicleType,
  formatTime,
} from '@/util';

import BatchAssign from '@/components/BatchAssign.vue';
import BridgeLayout from '@/components/BridgeLayout.vue';
import DateRange from '@/components/DateRange.vue';
import Dialog from '@/components/shared/Dialog';
import IdPicker from '@/components/IdPicker.vue';
import ViewAvailableVehicles from '@/components/ViewAvailableVehicles.vue';
import Assignment from './Assignment.vue';
import AssignmentListMenu from './Menu.vue';
import DriverLog from '../Tables/DriverLog.vue';

export default {
  name: 'AssignmentList',
  inject: ['eventHub'],
  components: {
    Assignment,
    AssignmentListMenu,
    BatchAssign,
    BridgeLayout,
    DateRange,
    Dialog,
    DriverLog,
    IdPicker,
    ViewAvailableVehicles,
  },
  data() {
    return {
      allExpanded: false,
      appliedFilters: [],
      appliedSort: '',
      config: {},
      currentPage: 1,
      currentSort: 0,
      driverLogDialog: false,
      filteredAssignments: [],
      format,
      perPage: 20,
      fromUnixTime,
      loading: true,
      page: 1,
      selectedItem: { approval: { history: [] } },
      sortOrder: -1,
      uniq,
      sortOptions: [
        { label: 'Depart Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'tripRequestId' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Vehicle Owner', sortBy: 'vehicleOwner' },
        { label: 'Vehicle', sortBy: 'vehicleNumber' },
        { label: 'Vehicle Type', sortBy: 'vehicleType' },
      ],
    };
  },
  computed: {
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    ...mapGetters('fundingSource', ['fundingSourcesById', 'fundingSources']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('tripRequest', ['tripRequests', 'tripRequestsById']),
    ...mapGetters('assignment', ['assignments', 'assignmentsGrouped']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('config', ['tripRequestConfig', 'notifyConfig']),
    ...mapGetters('app', ['assignmentListFilters', 'assignmentListSort', 'currentSemester']),
    ...mapGetters('user', ['me', 'vehicleOwners']),
    totalPages() {
      return Math.ceil(this.filteredAssignments.length / this.perPage);
    },
    reportSchoolYear() {
      const s = this.currentSemester.name ? this.currentSemester.name.split('-') : [];
      if (s.length) return s[0] + '+%7E+' + s[1];
      return '';
    },
    paginatedAssignments() {
      return this.filteredAssignments.slice().splice((this.currentPage - 1) * this.perPage, this.perPage);
    },
    reportLocations() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.readOnly
      )
        return '';
      const locationIds = uniq(this.me.roles.map((e) => e.locationIds).flat()).filter((e) => e);
      const locationNames = locationIds.map((e) => this.locationsById[e].name.replace(/ /g, '+').replace(/'/g, '%27'));
      return locationIds.length ? locationNames.join('%7C') : '';
    },
    screenHeight() {
      return window.innerHeight - 220;
    },
  },
  created() {
    this.eventHub.$on('filterAssignments', (filters) => this.filterAssignments(filters));
    this.eventHub.$on('openDateRangeFilterForAssignments', () => this.showDateRangeFilter());
    this.eventHub.$on('openIdFilterForAssignments', () => this.showIdFilter());
    this.eventHub.$on('findVehiclesRequestedForAssignments', () => this.showFindVehicles());
    this.eventHub.$on('batchAssignRequestedForAssignments', () => this.showBatchAssign());
    this.eventHub.$on('downloadRequestedForAssignments', () => this.download());
    this.eventHub.$on('setAssignmentSort', (index) => this.setCurrentSort(index));
    this.eventHub.$on('openDriverLogs', () => this.openDriverLogs());
  },
  beforeDestroy() {
    this.eventHub.$off('refreshAssignmentList');
    this.eventHub.$off('filterAssignments');
    this.eventHub.$off('openDateRangeFilterForAssignments');
    this.eventHub.$off('openIdFilterForAssignments');
    this.eventHub.$off('findVehiclesRequestedForAssignments');
    this.eventHub.$off('batchAssignRequestedForAssignments');
    this.eventHub.$off('downloadRequestedForAssignments');
    this.eventHub.$off('setAssignmentSort');
    this.eventHub.$off('openDriverLogs');
  },
  async mounted() {
    await this.refreshAssignments();
    await this[GET_USERS]();

    this.fetchItemsConfigs();
  },
  methods: {
    ...mapActions('assignment', [GET_ASSIGNMENTS, PRINT_ASSIGNMENTS]),
    ...mapActions('tripRequest', [DOWNLOAD_TRIP_TICKET]),
    ...mapActions('config', [GET_CONFIG, GET_SPECIAL_INDICATORS]),
    ...mapActions('user', [GET_USERS, GET_VEHICLE_OWNERS]),
    ...mapMutations('assignment', ['sortAssignments']),
    ...mapMutations('app', ['setAssignmentListFilters', 'setAssignmentListSort']),
    pluralize,
    async fetchItemsConfigs() {
      this.config = await this[GET_CONFIG]('tripRequestForm');

      this.setCurrentSort(this.assignmentListSort.index, true);

      this.eventHub.$emit('defaultFilterAssignments');
    },
    async refreshAssignments() {
      this.loading = true;

      await this[GET_ASSIGNMENTS]({ tripRequestIds: this.tripRequests.map((e) => e.id) });

      this.currentSort = this.currentSort || 0;

      this.filterAssignments(this.assignmentListFilters);

      this.loading = false;
    },
    checkFundingLevel(level) {
      if (!level) return false;

      const pending = this.approvalLevelsById[level.id];

      if (pending.criteria && pending.criteria.some((e) => e.label == 'Funding Source')) return true;

      return false;
    },
    checkFundingApprovers(trip, values) {
      if (!trip.fundingSources.length) return false;

      const approverIds = trip.fundingSources
        .map((e) => this.fundingSourcesById[e.fundingSourceId].approverId)
        .filter((e) => e);

      return approverIds.some((e) => values.includes(e)) && this.checkFundingLevel(trip.approval.awaitingApproval);
    },
    filterAssignments(filters) {
      this.currentPage = 1;
      this.$refs.bridgeLayout.resetScroll();

      this.setAssignmentListFilters([...filters]);

      filters = filters ? this.combineFilters(filters) : [];

      const filteredAssignments = [...this.assignments];

      for (let filter of filters) {
        for (let a of filteredAssignments) {
          a.include = false;

          if (filter.field == 'text') {
            if (
              filter.values.some((e) => a.tripRequestId.toString().includes(e) || a.batchId.toString().includes(e)) ||
              filter.values.some((e) =>
                this.locationsById[a.locationId]?.name.toLowerCase().includes(e.toLowerCase())
              ) ||
              filter.values.some((e) =>
                this.destinationsById[a.destinationId].name.toLowerCase().includes(e.toLowerCase())
              ) ||
              filter.values.some((e) => this.tripTypesById[a.tripTypeId].name.toLowerCase().includes(e.toLowerCase()))
            ) {
              a.include = true;
            }
          } else if (filter.field == 'date') {
            if (filter.values.includes('upcoming') && new Date(a.leaveDate) >= new Date(todayString())) {
              a.include = true;
            }

            if (filter.values.includes('today') && a.leaveDate == todayString()) {
              a.include = true;
            }
          } else if (filter.field == 'dateRange') {
            const s = filter.values[0].split(' - ');

            if (
              (new Date(a.leaveDate) >= new Date(s[0]) && new Date(a.leaveDate) <= new Date(s[1])) ||
              (new Date(a.leaveDate) <= new Date(s[1]) && new Date(a.returnDate) >= new Date(s[1]))
            ) {
              a.include = true;
            }
          } else if (filter.field == 'status' && filter.values.includes(a.status)) {
            a.include = true;
          } else if (filter.field == 'id' && filter.values.includes(a.tripRequestId)) {
            a.include = true;
          } else if (filter.field == 'vehicleOwner') {
            const owner = getVehicleOwner(a);

            if (owner && filter.values.includes(owner.email)) {
              a.include = true;
            }
          } else if (filter.field == 'driver') {
            const ids = filter.values.filter((e) => typeof e == 'number') || [];
            const names = filter.values.filter((e) => typeof e == 'string') || [];

            if (a.driverId && ids.includes(a.driverId)) {
              a.include = true;
            } else if (a.driver && a.driver.length && names.includes(a.driver)) {
              a.include = true;
            }
          } else if (filter.field == 'vehicleLocation' && a.vehicleId) {
            const l = getVehicleLocation(a.vehicleId, true);
            if (l && filter.values.includes(l.id)) a.include = true;
          } else if (filter.field == 'vehicleTypeId') {
            const vehicleType = getAssignmentVehicleType(a);
            if (vehicleType && filter.values.includes(vehicleType.id)) a.include = true;
          }
          // else if (filter.field == 'awayForLunch' && a.awayForLunch && a.needLunch) {
          //   a.include = true;
          // }
          else if (filter.field == 'pendingDriver' && !a.driverId && !a.driver) {
            a.include = true;
          } else if (filter.field == 'pendingVehicle' && !a.vehicleId) {
            a.include = true;
          } else if (filter.field == 'endMileage' && a.endMileage) {
            a.include = true;
          } else if (filter.field == 'endTime' && a.endTime) {
            a.include = true;
          } else if (filter.field == 'startMileage' && !a.startMileage) {
            a.include = true;
          } else if (filter.field == 'startTime' && !a.startTime) {
            a.include = true;
          } else if (filter.field == 'tripEventId' && a.tripEventIds.some((e) => filter.values.includes(e))) {
            a.include = true;
          } else if (
            filter.field == 'fundingSource' &&
            filter.values.includes('any') &&
            this.tripRequestsById[a.tripRequestId].fundingSources.length > 0
          ) {
            a.include = true;
          } else if (
            filter.field == 'fundingSourceId' &&
            this.tripRequestsById[a.tripRequestId].fundingSources
              .map((e) => e.fundingSourceId)
              .some((e) => filter.values.includes(e))
          ) {
            a.include = true;
          } else if (filter.field == 'zone' && filter.values.includes(this.getTripZone(a))) {
            a.include = true;
          } else if (filter.field == 'batchId' && (filter.values.includes(a.batchId) || filter.values.includes(a.id))) {
            a.include = true;
          } else if (filter.values.includes(a[filter.field])) {
            a.include = true;
          }
          // else if (filter.field == 'recurring' && a.recurrence.length) a.include = true;
          // else if (filter.field == 'fundingSource' && filter.values.includes('any') && a.fundingSources.length > 0)
          //   a.include = true;
          // else if (
          //   filter.field == 'fundingSource' &&
          //   filter.values.includes('payableToThirdParty') &&
          //   a.payableToThirdParty
          // )
          //   a.include = true;
          // else if (
          //   filter.field == 'fundingSourceId' &&
          //   a.fundingSources.map((e) => e.fundingSourceId).some((e) => filter.values.includes(e))
          // )
          //   a.include = true;
          else if (
            filter.field == 'fundingApprover' &&
            this.checkFundingApprovers(this.tripRequestsById[a.tripRequestId], filter.values)
          ) {
            a.include = true;
          }
        }
      }

      this.filteredAssignments = filters.length ? filteredAssignments.filter((e) => e.include) : filteredAssignments;
      this.sortAssignments(true, false);
    },
    combineFilters(filters) {
      const fields = uniq(filters.map((e) => e.field));
      const combined = [];

      for (let field of fields) {
        let values = [];
        const currentFilters = filters.filter((e) => e.field == field);

        for (let cf of currentFilters)
          if (Array.isArray(cf.value)) values = values.concat(cf.value);
          else values.push(cf.value);

        combined.push({ field, values });
      }

      return combined;
    },
    setCurrentSort(index, maintain) {
      this.currentSort = index;
      this.sortAssignments(maintain);
    },
    sortAssignments(maintain, persist = true) {
      const option = this.sortOptions[this.currentSort];

      this.sortOptions.forEach((e) => (e.order = null));

      option.order = this.assignmentListSort.index == this.currentSort ? this.assignmentListSort.order : 'asc';

      if (!maintain) option.order = option.order == 'desc' ? 'asc' : 'desc';

      if (option.sortBy == 'locationName')
        this.filteredAssignments.forEach((e) => (e.locationName = this.locationsById[e.locationId].name));
      if (option.sortBy == 'destinationName')
        this.filteredAssignments.forEach((e) => (e.destinationName = this.destinationsById[e.destinationId].name));
      if (option.sortBy == 'vehicleOwner')
        this.filteredAssignments.forEach((e) => {
          const vo = this.vehicleOwners.find((v) => v.locationId == e.assignmentLocationId);
          e.vehicleOwner = vo ? vo.displayName || vo.userEmail : '';
        });
      if (option.sortBy == 'vehicleType')
        this.filteredAssignments.forEach((e) => (e.vehicleType = this.vehicleTypesById[e.vehicleTypeId].name));
      if (option.sortBy == 'vehicleNumber')
        this.filteredAssignments.forEach((e) => {
          e.vehicleNumber = e.vehicle || e.vehicleId ? e.vehicle || this.vehiclesById[e.vehicleId].name : '';
        });

      if (option.order == 'desc') {
        this.filteredAssignments.sort((a, b) => {
          if (option.sortBy == 'leaveDate') {
            const aLeaveTime = a['leaveTime'] ? formatTime(a['leaveTime']) : '00:00:00';
            const bLeaveTime = b['leaveTime'] ? formatTime(b['leaveTime']) : '00:00:00';
            return (
              new Date(`${b[option.sortBy]}T${bLeaveTime}:00Z`) - new Date(`${a[option.sortBy]}T${aLeaveTime}:00Z`)
            );
          }
          return option.sortBy == 'locationName' ||
            option.sortBy == 'destinationName' ||
            option.sortBy == 'vehicleOwner' ||
            option.sortBy == 'vehicleNumber' ||
            option.sortBy == 'vehicleType'
            ? b[option.sortBy].localeCompare(a[option.sortBy], undefined)
            : b[option.sortBy] - a[option.sortBy];
        });
      } else {
        this.filteredAssignments.sort((a, b) => {
          if (option.sortBy == 'leaveDate') {
            const aLeaveTime = a['leaveTime'] ? formatTime(a['leaveTime']) : '00:00:00';
            const bLeaveTime = b['leaveTime'] ? formatTime(b['leaveTime']) : '00:00:00';
            return (
              new Date(`${a[option.sortBy]}T${aLeaveTime}:00Z`) - new Date(`${b[option.sortBy]}T${bLeaveTime}:00Z`)
            );
          }

          return option.sortBy == 'locationName' ||
            option.sortBy == 'destinationName' ||
            option.sortBy == 'vehicleOwner' ||
            option.sortBy == 'vehicleNumber' ||
            option.sortBy == 'vehicleType'
            ? a[option.sortBy]?.localeCompare(b[option.sortBy], undefined)
            : a[option.sortBy] - b[option.sortBy];
        });
      }
      if (persist) this.setAssignmentListSort({ index: this.currentSort, order: option.order });
    },
    editRequest(item) {
      this.$router.push('/trip-request/' + item.tripRequestId);
    },
    printAssignmentList() {
      this.printAssignments({ uuid: randomString(16), assignmentIds: this.filteredAssignments.map((e) => e.id) });
    },
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    showFindVehicles() {
      this.$refs.fav.dialog = true;
    },
    showBatchAssign() {
      this.$refs.batchAssign.dialog = true;
    },
    async download() {
      if (this.filteredAssignments.length) {
        const includeDirections = await this.$myconfirm('Include directions in the Trip Tickets?');
        try {
          await this.downloadTripTicket({
            assignmentIds: this.filteredAssignments.map((e) => e.id),
            uuid: randomString(16),
            reportId: 0,
            includeDirections,
          });
        } catch (error) {
          this.$myalert.error(error.message);
        }
      } else this.$myalert.error('There are no Assignments in your list to create Trip Tickets for');
    },
    showDateRangeFilter() {
      this.$refs.dateRange.dialog = true;
    },
    showIdFilter() {
      this.$refs.idPicker.dialog = true;
    },
    getTripZone(trip) {
      if (this.tripRequestConfig.other.determineZoneBy == 'request') return this.locationsById[trip.locationId].zone;
      if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
        const reserveFromLocation = this.locationsById[trip.locationId].vehicleOrder[0] || 0;
        return reserveFromLocation ? this.locationsById[reserveFromLocation].zone : null;
      }
    },
    checkVehicleOwner(trip, values) {
      const ownerEmail = trip.assignmentLocationId ? this.locationsById[trip.assignmentLocationId].vehicleOwner : null;
      if (!ownerEmail) return false;
      return values.includes(ownerEmail);
    },
    openDriverLogs() {
      this.driverLogDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.w-full {
  width: 100%;
}
.card-spacing {
  margin-bottom: 10px !important;
}
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.right {
  float: right;
}
.button-chip {
  height: 36px !important;
  font-size: 16px !important;
}
.v-tooltip__content {
  background-color: #fff !important;
  border: 1px solid #c9c6c6 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  opacity: 1 !important;
}
.w-full {
  width: 100%;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.context-menu {
  position: sticky;
  top: 60px;
  z-index: 6;
}
</style>
