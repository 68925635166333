<template>
  <v-card>
    <v-container fluid class="pa-0" :class="`${this.status.color.split(' ')[0]}-border `">
      <v-row
        class="ma-0 pa-0"
        :class="
          this.expand
            ? `${this.status.color} white--text ${this.status.color.split(' ')[0]}-border-bottom`
            : 'black--text'
        "
      >
        <v-col
          cols="auto"
          class="text-center clickable ma-0 no-space d-flex flex-column justify-center"
          :class="numClass"
          @click="
            $router.push(
              returnToDashboard
                ? `/trip-request/${assignment.tripRequestId}?assignment=true&dashboard=true`
                : `/trip-request/${assignment.tripRequestId}?assignment=true`
            )
          "
        >
          <div v-if="assignment.batchId" class="text-subtitle-1 text-center">Batch #{{ assignment.batchId }}</div>
          <div class="text-h5 text-center font-weight-bold">#{{ assignment.tripRequestId }}</div>
          <div class="icon-wrapper">
            <v-tooltip bottom v-if="displayIcons.mileageSubmitted || displayIcons.hoursSubmitted">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    expand ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} ` : `white--text `
                  "
                >
                  mdi-clipboard-edit
                </v-icon>
              </template>
              <span>
                {{
                  displayIcons.hoursSubmitted && displayIcons.mileageSubmitted
                    ? 'Hours & Mileage'
                    : displayIcons.mileageSubmitted
                    ? 'Mileage'
                    : 'Hours'
                }}
                submitted
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="assignment.leg > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    expand ? `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} ` : `white--text `
                  "
                  >{{ assignment.leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}
                </v-icon>
              </template>
              <span>
                {{ `Split Trip ${assignment.leg == 1 ? 'Drop-Off' : 'Return'} Leg` }}
              </span>
            </v-tooltip>
          </div>
        </v-col>
        <template v-if="expand">
          <v-col cols="2" md="2" class="pa-0 ml-2">
            <div class="text-overline font-weight-bold">Assignment</div>
            <div class="text-subtitle-1">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-bus</v-icon
              >{{ vehicle || 'None assigned' }}
            </div>
            <div class="text-subtitle-1 py-0 my-0" v-if="getVehicleLocation(assignment.vehicleId)">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-map-marker-radius</v-icon
              >{{ getVehicleLocation(assignment.vehicleId) }}
            </div>
            <div class="text-subtitle-1">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-account</v-icon
              >{{ driver || 'None assigned' }}
            </div>
            <!-- <div class="text-subtitle-1" v-if="assignment.leg > 0">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >{{ assignment.leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}</v-icon
              >{{ `Split Trip ${assignment.leg == 1 ? 'Drop-Off' : 'Return'} Leg` }}
            </div> -->
          </v-col>

          <v-col cols="2" class="pa-0 pl-4">
            <div class="text-overline font-weight-bold">Vehicle Owner</div>
            <v-tooltip bottom :disabled="!vehicleOwner.email">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-subtitle-1" v-bind="attrs" v-on="on">
                  {{ vehicleOwner.display }}
                </div>
              </template>
              <span v-html="vehicleOwner.email"></span>
            </v-tooltip>
          </v-col>

          <v-col cols="2" class="pa-0">
            <div class="text-overline font-weight-bold">Trip Duration</div>
            <div class="text-subtitle-1">{{ totalTime }}</div>
          </v-col>

          <v-col cols="2" class="pa-0">
            <div class="text-overline font-weight-bold">Trip Mileage</div>
            <div class="text-subtitle-1">{{ totalDistance }}</div>
          </v-col>

          <v-col cols="4" md="2" class="pa-0">
            <div class="text-overline font-weight-bold">Location / Destination</div>
            <div class="text-subtitle-1">{{ locationName }}</div>
            <div class="text-subtitle-1">{{ destinationName }}</div>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" md="2" class="pa-0 ml-2">
            <div class="text-overline font-weight-bold">Assignment</div>
            <div class="text-subtitle-1 py-0 my-0">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-bus</v-icon
              >{{ vehicle || 'None assigned' }}
            </div>
            <div class="text-subtitle-1 py-0 my-0" v-if="getVehicleLocation(assignment.vehicleId)">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-map-marker-radius</v-icon
              >{{ getVehicleLocation(assignment.vehicleId) }}
            </div>
            <div class="text-subtitle-1">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >mdi-account</v-icon
              >{{ driver || 'None assigned' }}
            </div>
            <!-- <div class="text-subtitle-1" v-if="assignment.leg > 0">
              <v-icon
                :class="
                  expand ? `white--text ` : `${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} `
                "
                >{{ assignment.leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}</v-icon
              >{{ `Split Trip ${assignment.leg == 1 ? 'Drop-Off' : 'Return'} Leg` }}
            </div> -->
          </v-col>

          <v-col cols="12" md="1" class="pa-0">
            <div class="text-overline font-weight-bold">Vehicle Owner</div>
            <v-tooltip bottom :disabled="!vehicleOwner.email">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-subtitle-1" v-bind="attrs" v-on="on">
                  {{ vehicleOwner.display }}
                </div>
              </template>
              <span v-html="vehicleOwner.email"></span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" md="2" class="pa-0">
            <div class="text-overline font-weight-bold">Leave / Return</div>
            <div class="text-subtitle-1">{{ leaveDate }} @ {{ leaveTime }}</div>
            <div class="text-subtitle-1">{{ returnDate }} @ {{ returnTime }}</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="1" class="pa-0">
            <div class="text-overline font-weight-bold">Duration / Mileage</div>
            <div class="text-subtitle-1">{{ totalTime }}</div>
            <div class="text-subtitle-1">{{ totalDistance }}</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="2" class="pa-0">
            <div class="text-overline font-weight-bold">Location / Destination</div>
            <div class="text-subtitle-1">{{ locationName }}</div>
            <div class="text-subtitle-1">{{ destinationName }}</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="1" class="pa-0">
            <div class="text-overline font-weight-bold">Requester</div>
            <div class="text-subtitle-1">{{ requesterName }}</div>
          </v-col>
          <v-spacer></v-spacer>

          <v-col v-if="notifyConfig.driverAccept || notifyConfig.driverDecline" cols="12" md="2" class="pa-0">
            <div class="text-overline font-weight-bold">Driver Status</div>
            <div class="text-subtitle-1">{{ status.display }}</div>
          </v-col>
        </template>
        <v-spacer></v-spacer>
        <aside class="expand-button">
          <v-btn icon @click="handleExpand">
            <v-icon :class="expand ? 'white--text' : ''">{{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-menu v-if="!hideQuickActions" bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon :class="expand ? 'white--text' : ''">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-if="hasTripTicketTemplate" @click="download()">
                <v-list-item-icon><v-icon color="primary">mdi-download</v-icon></v-list-item-icon>
                <v-list-item-title>Trip Ticket</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canEnterMileage" @click="quickEnter()">
                <v-list-item-icon><v-icon color="primary">mdi-pencil</v-icon></v-list-item-icon>
                <v-list-item-title>Enter Mileage/Time</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="
                  notifyConfig.driverAccept && assignment.status == 0 && (me.is.superAdmin || me.is.transportationAdmin)
                "
                @click="accept(true)"
              >
                <v-list-item-icon><v-icon color="primary">mdi-check-circle-outline</v-icon></v-list-item-icon>
                <v-list-item-title>Accept</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="
                  notifyConfig.driverDecline &&
                  assignment.status == 0 &&
                  (me.is.superAdmin || me.is.transportationAdmin)
                "
                @click="accept(false)"
              >
                <v-list-item-icon><v-icon color="primary">mdi-cancel</v-icon></v-list-item-icon>
                <v-list-item-title>Decline</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </aside>
      </v-row>
      <v-divider v-if="expand"></v-divider>
      <v-expand-transition>
        <v-row class="ma-0" v-show="expand">
          <v-col cols="12" md="4">
            <v-row class="px-4">
              <v-col cols="12" md="4">
                <div class="text-overline">Leave</div>
                <div class="text-h5 font-weight-bold">{{ leaveDate }}</div>
                <div class="text-subtitle-1">{{ leaveTime }}</div>
                <div class="text-overline">Return</div>
                <div class="text-h5 font-weight-bold">{{ returnDate }}</div>
                <div class="text-subtitle-1">{{ returnTime }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <div class="text-overline">Route</div>
                <v-list flat class="py-0">
                  <v-list-item v-for="(stop, i) of route" :key="i" class="pl-0 py-0">
                    <v-list-item-icon>
                      <v-icon>{{ stop.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ stop.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="auto" class="pl-4" md="2">
            <div class="text-overline font-weight-bold">Vehicle Type</div>
            <div class="text-subtitle-1">{{ vehicleType }}</div>
            <div class="text-overline font-weight-bold">Trip Type</div>
            <div class="text-subtitle-1">{{ tripType }}</div>
            <div class="text-overline font-weight-bold">Trip Event(s)</div>
            <div class="text-subtitle-1">{{ tripEvents }}</div>
            <div class="text-overline font-weight-bold">Bid ID</div>
            <div class="text-subtitle-1">-</div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="auto" class="pl-4" md="2">
            <div class="text-overline font-weight-bold">Wheelchair Lift</div>
            <div class="text-subtitle-1">{{ assignment.numWheelchairSlot > 0 ? 'Yes' : 'No' }}</div>
            <div class="text-overline font-weight-bold">Special Equipment</div>
            <div class="text-subtitle-1">{{ specEq }}</div>
            <div class="text-overline font-weight-bold">Special Indicators</div>
            <div class="text-subtitle-1">{{ specIns }}</div>
            <div class="text-overline font-weight-bold">Zone</div>
            <div class="text-subtitle-1">{{ zone }}</div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="auto" class="pl-4" md="1">
            <div class="text-overline font-weight-bold"># Adults / Students</div>
            <div class="text-subtitle-1">{{ assignment.totalAdults }} / {{ assignment.totalStudents }}</div>
            <div class="text-overline font-weight-bold">Grade(s)</div>
            <div class="text-subtitle-1">{{ grades }}</div>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="auto" class="pl-4" md="3">
            <v-row dense>
              <v-col cols="6">
                <time-picker
                  :disabled="activeFab == 0"
                  dense
                  label="Start Time"
                  outlined
                  v-model="enterMileTime.startTime"
                ></time-picker>

                <time-picker
                  :disabled="activeFab == 0"
                  dense
                  label="End Time"
                  outlined
                  v-model="enterMileTime.endTime"
                ></time-picker>

                <v-text-field
                  :disabled="activeFab == 0"
                  :min="0"
                  dense
                  label="Start Odometer"
                  outlined
                  prepend-icon="mdi-bus"
                  type="number"
                  v-model="enterMileTime.startMileage"
                ></v-text-field>

                <v-text-field
                  :disabled="activeFab == 0"
                  :min="0"
                  dense
                  label="End Odometer"
                  outlined
                  prepend-icon="mdi-bus"
                  type="number"
                  v-model="enterMileTime.endMileage"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pl-4">
                <div class="text-overline font-weight-bold">Total Time</div>
                <div class="text-subtitle-1">{{ calculatedTime }}</div>
                <div class="text-overline font-weight-bold mt-16">Total Mileage</div>
                <div class="text-subtitle-1">{{ calculatedMileage }}</div>
              </v-col>
            </v-row>

            <v-fab-transition v-if="expand">
              <v-btn
                :color="fabs[activeFab].color"
                :disabled="hasTimeOrMileageError || saving || invoiceReady"
                :key="fabs[activeFab].icon"
                :loading="saving"
                @click="handleEnterMileTime"
                bottom
                class="fab-edit-save"
                dark
                fab
                right
                small
                v-if="canEnterMileage"
              >
                <v-icon>{{ fabs[activeFab].icon }}</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { SAVE_ASSIGNMENT, ACCEPT_ASSIGNMENT } from '@/store/modules/Assignment/actions';
import { DOWNLOAD_TRIP_TICKET } from '@/store/modules/TripRequest/actions';
import { format, fromUnixTime, differenceInMinutes, differenceInMilliseconds } from 'date-fns';
import {
  getTime,
  hoursAndMinutes,
  randomString,
  getVehicleLocation,
  getVehicleOwner,
  getAssignmentVehicleType,
} from '@/util';
import TimePicker from '@/components/TimePicker.vue';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'Assignment',
  inject: ['eventHub'],
  components: { TimePicker },
  props: {
    assignmentProp: Object,
    allExpanded: Boolean,
    hideQuickActions: Boolean,
    returnToDashboard: Boolean,
  },
  data() {
    return {
      format,
      fromUnixTime,
      getVehicleLocation,
      VEHICLE_TYPES,
      assignment: {},
      expand: false,
      color: 'primary',
      enterMileTime: {
        startTime: '',
        endTime: '',
        startMileage: null,
        endMileage: null,
      },
      calculatedTime: '',
      calculatedMileage: '',
      hasTimeOrMileageError: false,
      saving: false,
      activeFab: 0,
      fabs: [
        {
          color: 'primary',
          icon: 'mdi-pencil',
        },
        {
          color: 'green',
          icon: 'mdi-content-save',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('app', ['hasTripTicketTemplate']),
    ...mapGetters('user', ['usersById']),
    ...mapGetters('assignment', ['assignmentById']),
    ...mapGetters('user', ['me', 'usersByEmail', 'vehicleOwners']),
    ...mapGetters('location', ['locationsById', 'locations']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators', 'driverConfig', 'permissions', 'notifyConfig']),
    numClass() {
      return this.expand
        ? `white ${this.status.color.split(' ')[0]}--text text--${this.status.color.split(' ')[1]}`
        : `${this.status.color} white--text`;
    },
    grades() {
      if (this.assignment.gradeLevels && this.assignment.gradeLevels.length && this.assignment.gradeLevels[0] == 'All')
        return 'All';
      else if (this.assignment.gradeLevels && this.assignment.gradeLevels.length)
        return this.assignment.gradeLevels.join(', ');
      else return 'N/A';
    },
    invoiceEditable() {
      return ['sent', 'paid', 'partially_paid', 'partially_sent'].indexOf(this.assignment?.invoiceStatus) === -1;
    },
    requesterName() {
      const submittedUser = this.assignment.submittedUser;
      if (submittedUser) {
        if (this.usersById[submittedUser] !== undefined) return this.usersById[submittedUser]?.displayName;
        else return 'Legacy Requester';
      } else {
        return '-';
      }
    },
    displayIcons() {
      const assignment = this.assignment;
      return {
        mileageSubmitted: assignment.endMileage > 0,
        hoursSubmitted: assignment.endTime.length > 0,
      };
    },
    status() {
      if (this.assignment.status == 1 || (this.assignment.status == 0 && !this.notifyConfig.driverAccept))
        return { display: 'Assigned', color: 'green darken-1' };
      else if (this.assignment.status == -1) return { display: 'Declined', color: 'red accent-2' };
      else if (this.assignment.status == -3) return { display: 'Canceled', color: 'red accent-2' };
      else return { display: 'Pending Driver Acceptance', color: 'orange darken-3' };
    },
    invoiceReady() {
      if (this.assignment.invoiceStatus == 'pending' || this.assignment.invoiceStatus === '') return false;
      return true;
    },
    leaveDate() {
      const d = new Date(this.assignment.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'EEE MMM dd yy');
    },
    leaveTime() {
      const s = this.assignment.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      const d = new Date(this.assignment.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'EEE MMM dd yy');
    },
    returnTime() {
      const s = this.assignment.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    driverMileageEditable() {
      return this.me.is.driver && this.driverConfig.enterMileage;
    },
    requestorMileageEditable() {
      return this.permissions.requester?.enterMileage;
    },
    schoolFinanceMileageEditable() {
      return this.me.is.schoolFinance && this.permissions.schoolFinance?.enterMileage;
    },
    locationName() {
      return this.assignment.locationId
        ? `${this.locationsById[this.assignment.locationId].name} (${
            this.locationsById[this.assignment.locationId].code
          })`
        : '-';
    },
    destinationName() {
      return this.destinationsById[this.assignment.destinationId]
        ? this.destinationsById[this.assignment.destinationId].name
        : '-';
    },
    route() {
      if (this.assignment.stops)
        return this.assignment.stops.map((e) => {
          let icon = 'mdi-map-marker';
          if (e.isLocation) icon = 'mdi-school';
          else if (e.isDestination) icon = 'mdi-map-marker-check';
          return { name: e.name, icon };
        });
      else return [];
    },
    vehicleType() {
      const vt = getAssignmentVehicleType(this.assignment);
      return vt ? vt.name : '';
    },
    tripType() {
      const tt = this.tripTypesById[this.assignment.tripTypeId];
      return tt ? tt.name : '';
    },
    tripEvents() {
      return this.assignment.tripEventIds.map((e) => this.tripEventsById[e]?.name).join(', ');
    },
    specEq() {
      const r = [];
      if (this.assignment.numWheelchairSlot > 0) r.push('Wheelchair Slots');
      if (this.assignment.numSafetyVest > 0) r.push('Safety Vests');
      if (this.assignment.numFoldDownSeat > 0) r.push('Fold Down Seats');
      return r.length ? r.join(', ') : '-';
    },
    specIns() {
      if (!this.assignment.specialIndicators) return '-';
      const r = [];
      this.assignment.specialIndicators.forEach((e) => {
        const si = this.specialIndicators.find((s) => s.id == e);
        r.push(si ? si.name : '');
      });
      return r.join(', ');
    },
    vehicles() {
      if (this.vehicleTypesById && this.additionalTransportationsById) {
        const addT = {
          3: 'Approved Charter',
          2: 'Contractor',
          1: 'Rental / Dealership',
        };
        if (this.assignment.vehicleType === this.VEHICLE_TYPES.NORMAL)
          return `${this.assignment.numVehicles} (${this.vehicleTypesById[this.assignment.vehicleTypeId].name})`;
        else if (this.assignment.vehicleType !== this.VEHICLE_TYPES.NORMAL) {
          return `${this.assignment.numVehicles} (${addT[this.assignment.vehicleType]} - ${
            this.assignment.additionalTransportationId
              ? this.additionalTransportationsById[this.assignment.additionalTransportationId].name
              : 'None Selected'
          })`;
        } else return 'No vehicles requested';
      } else return '-';
    },
    zone() {
      if (this.tripRequestConfig && this.tripRequestConfig.other) {
        if (this.tripRequestConfig.other.determineZoneBy == 'request')
          return this.assignment.locationId ? this.locationsById[this.assignment.locationId].zone : '';
        if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[this.assignment.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation].zone;
        }
      }
      return '-';
    },
    wasRescheduled() {
      const i = this.assignment.auditHistory.findIndex((e) => e.description.includes('Rescheduled'));
      if (i >= 0)
        return {
          show: true,
          message: this.assignment.auditHistory[i].description,
        };
      else return { show: false };
    },
    canCreateDuplicate() {
      if (
        (this.assignmentConfig && this.assignmentConfig.display.duplicate) ||
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin
      )
        return true;
      else return false;
    },
    totalTime() {
      return getTime(
        differenceInMilliseconds(
          new Date(this.assignment.returnDate + ' ' + this.assignment.returnTime),
          new Date(this.assignment.leaveDate + ' ' + this.assignment.leaveTime)
        )
      );
    },
    totalDistance() {
      return (this.assignment.distance || 0) + ' miles';
    },
    vehicle() {
      if (this.assignment.vehicleId) return this.vehiclesById[this.assignment.vehicleId].name;
      else if (this.assignment.vehicle) return this.assignment.vehicle;
      else return null;
    },
    driver() {
      if (this.assignment.driverId)
        return this.driversById[this.assignment.driverId]
          ? `${this.driversById[this.assignment.driverId].firstName} ${
              this.driversById[this.assignment.driverId].lastName
            }`
          : '-';
      else if (this.assignment.driver) return this.assignment.driver;
      else return null;
    },
    vehicleOwner() {
      const owner = getVehicleOwner(this.assignment);
      if (!owner) return { display: '-' };
      return owner.displayName ? { display: owner.displayName, email: owner.email } : { display: owner.email };
    },
    canEnterMileage() {
      if (!this.invoiceEditable) return false;

      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.driverMileageEditable ||
        this.schoolFinanceMileageEditable ||
        this.requestorMileageEditable
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions('assignment', [SAVE_ASSIGNMENT, ACCEPT_ASSIGNMENT]),
    ...mapActions('tripRequest', [DOWNLOAD_TRIP_TICKET]),
    ...mapMutations('assignment', ['updateAssignment']),
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    handleExpand() {
      this.expand = !this.expand;
    },
    async saveMileTime() {
      this.saving = true;
      try {
        const r = await this.saveAssignment({
          id: this.assignment.id,
          startDate: this.assignmentProp.leaveDate,
          startTime: this.enterMileTime.startTime,
          endDate: this.assignmentProp.returnDate,
          endTime: this.enterMileTime.endTime,
          startMileage: Number(this.enterMileTime.startMileage),
          endMileage: Number(this.enterMileTime.endMileage),
          raw: true,
        });
        if (r && r.id) {
          this.updateAssignment({
            ...this.assignment,
            startTime: this.enterMileTime.startTime,
            endTime: this.enterMileTime.endTime,
            startMileage: Number(this.enterMileTime.startMileage),
            endMileage: Number(this.enterMileTime.endMileage),
          });
          this.assignment = this.assignmentById(r.id);
          this.$myalert.success('Times & Mileage saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    async accept(status) {
      try {
        const r = await this.acceptAssignment({
          assignmentId: this.assignment.id,
          status: status ? 1 : -1,
          // notes: this.notes
        });
        if (r && r.done) {
          this.$myalert.success(`Assignment ${status ? 'accepted' : 'denied'}`);
          this.updateAssignment({ ...this.assignment, status: status ? 1 : -1 });
          this.assignment = this.assignmentById(this.assignment.id);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async handleEnterMileTime() {
      if (this.activeFab == 1) {
        await this.saveMileTime();
        this.activeFab = 0;
      } else this.activeFab = 1;
    },
    async download() {
      try {
        const includeDirections = await this.$myconfirm('Include directions in the Trip Ticket?');
        await this.downloadTripTicket({
          assignmentIds: [this.assignment.id],
          uuid: randomString(16),
          reportId: 0,
          includeDirections,
        });
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    quickEnter() {
      this.expand = true;
      this.activeFab = 1;
    },
    getVehicleInfo(vehicleId) {
      return {
        display: `${this.vehiclesById[vehicleId].name}`,
        tooltip:
          this.vehiclesById[vehicleId].depotId && this.vehiclesById[vehicleId].depotId.schoolId
            ? `(${this.locationsById[this.stopsById[this.vehiclesById[vehicleId].depotId].schoolId].code}) ${
                this.locationsById[this.stopsById[this.vehiclesById[vehicleId].depotId].schoolId].name
              }`
            : null,
      };
    },
    getDriverInfo(driverId) {
      return `${this.driversById[driverId].firstName} ${this.driversById[driverId].lastName}`;
    },
    actualTime(value) {
      if (value.startTime && value.endTime) {
        const start = new Date(`${value.leaveDate}, ${value.startTime}`);
        const end = new Date(`${value.returnDate}, ${value.endTime}`);
        const diff = differenceInMinutes(end, start);
        return hoursAndMinutes(diff);
      } else return '-';
    },
    actualMileage(value) {
      if (value.startMileage && value.endMileage) return value.endMileage - value.startMileage + ' miles';
      else return '-';
    },
  },
  watch: {
    allExpanded(value) {
      this.expand = value;
    },
    expand(value) {
      if (!value) {
        this.activeFab = 0;
        this.enterMileTime = {
          startTime: this.assignment.startTime || '',
          endTime: this.assignment.endTime || '',
          startMileage: this.assignment.startMileage || null,
          endMileage: this.assignment.endMileage || null,
        };
      }
    },
    assignmentProp: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.assignment = value;
        this.enterMileTime = {
          startTime: value.startTime || '',
          endTime: value.endTime || '',
          startMileage: value.startMileage || null,
          endMileage: value.endMileage || null,
        };
        this.calculatedTime = this.actualTime(value);
        this.calculatedMileage = this.actualMileage(value);
      },
    },
    enterMileTime: {
      deep: true,
      handler: function (mileTime) {
        this.hasTimeOrMileageError = false;
        if (this.leaveDate != this.returnDate || this.activeFab == 0) return;

        const stringFoundStart = mileTime.startTime.match(/[A-Z]/g);
        const stringFoundEnd = mileTime.endTime.match(/[A-Z]/g);
        if (stringFoundStart?.length) mileTime.startTime = mileTime.startTime.slice(0, -3);
        if (stringFoundEnd?.length) mileTime.endTime = mileTime.endTime.slice(0, -3);

        if (parseFloat(mileTime.startTime.replace(':', '.')) > parseFloat(mileTime.endTime.replace(':', '.'))) {
          this.$myalert.error('End Time cannot come before the start Time');
          this.hasTimeOrMileageError = true;
        }

        if (parseInt(mileTime.startMileage) > parseInt(mileTime.endMileage)) {
          if (mileTime.endMileage != null) this.$myalert.error('Mileage cannot be less than 0');
          this.hasTimeOrMileageError = true;
        }

        const newValue = {
          leaveDate: this.assignmentProp.leaveDate,
          returnDate: this.assignmentProp.returnDate,
          ...mileTime,
        };
        this.calculatedTime = this.actualTime(newValue);
        this.calculatedMileage = this.actualMileage(newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.card-spacing {
  margin-bottom: 10px !important;
}
.clickable:hover {
  cursor: pointer;
}
.no-m-l {
  margin-left: 0px !important;
}
.p-r-6 {
  padding-right: 6px;
}
.fab-edit-save {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.green-border {
  border: 2px solid #43a047 !important;
}
.orange-border {
  border: 2px solid #ef6c00 !important;
}
.blue-border {
  border: 2px solid #1976d2 !important;
}
.amber-border {
  border: 2px solid #ffb300 !important;
}
.red-border {
  border: 2px solid #ff5252 !important;
}
.green-border-bottom {
  border-bottom: 2px solid #43a047 !important;
}
.orange-border-bottom {
  border-bottom: 2px solid #ef6c00 !important;
}
.blue-border-bottom {
  border-bottom: 2px solid #1976d2 !important;
}
.amber-border-bottom {
  border-bottom: 2px solid #ffb300 !important;
}
.red-border-bottom {
  border-bottom: 2px solid #ff5252 !important;
}
.icon-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 96px;
}
.no-space {
  padding: 0px 2px 0px 8px !important;
  margin: 0px !important;
}
.expand-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
