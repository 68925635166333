<template>
  <div>
    <v-treeview :items="items"></v-treeview>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import { GET_DRIVER_LOGS, SAVE_DRIVER_LOG } from '@/store/modules/DriverLog/actions';

export default {
  name: 'DriverLog',
  inject: ['eventHub'],
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters('driverLog', ['driverLogs']),
    ...mapGetters('driver', ['drivers', 'driversById']),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('driverLog', [GET_DRIVER_LOGS, SAVE_DRIVER_LOG]),
    async fetchItems() {
      await this.getDriverLogs();
      this.drivers.forEach((e, i) =>
        this.items.push({
          id: i + 1,
          name: `${this.driversById[e.id].lastName}, ${this.driversById[e.id].firstName}`,
          children: this.driverLogs[e.id]
            ? this.driverLogs[e.id].map((l) => ({
                id: l.id,
                name: this.getMessage(l),
              }))
            : [],
        })
      );
      this.loading = false;
    },
    getMessage(obj) {
      const date = format(fromUnixTime(obj.timestamp), 'PPP @ p');
      return `${date} by ${obj.loggedBy} - ${obj.message}`;
    },
    async saveItem(item) {
      try {
        const r = await this.saveDriver(item);
        if (r && r.id) {
          this.$myalert.success('Driver saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss"></style>
